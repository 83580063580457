import { css } from '@emotion/react'
import { Component, Fragment } from 'react';
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles';

// FINANCIERA COMPONENTS
import { CardElevation, TitleIcon, ButtonDegrade } from '@findep/mf-landings-core'

//MATERIAL UI COMPONENTS 
import { Snackbar, Grid, Box,Button } from "@material-ui/core"
import Typography from '@material-ui/core/Typography';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import Alert from '@material-ui/lab/Alert';
import ThumbUpAltOutlinedIcon from '@material-ui/icons/ThumbUpAltOutlined';

// COMPONENTES PROPIOS DEL SYSTEM
import Signature from '../../Signature/Signature';
import Disclousers from "../../pages/afi/Disclousers"

//MATERIAL ICONS
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';

//SERVICES FOR BFF 
import { PrescoreService } from '../../../services/LandingV4/prescore'
import DataUrlToFile from '../../../helpers/dataUrlToFile'
import { withThemeProps } from '@findep/microfronts-core';
import { CataloguesService } from '../../../services/LandingV4'
import { restoreFromSessionStorage, restoreFromSessionStorageAfiShort } from '../../../components/pages/afi/restoreSessionStorage';

import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'

const catalogues = new CataloguesService(); 

const phoneHelp = css`  
    color: rgba(0, 0, 0, 0.54);
    margin: 0;
    font-size: 0.75rem;
    
    text-align: justify;
    font-family: "Poppins", "Helvetica", "Arial", sans-serif;
    font-weight: 400;
    line-height: 1.66;
    letter-spacing: 0.03333em;
 `
const buttonCss = css`
    display: flex;
    justify-content: flex-center;
`

const useStyles = (theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
});

const mb = css`
  margin-bottom: 1rem;
`

const mx = css`
  margin-bottom: 1rem;
  margin-top: 1rem;
`

const root = css`
  padding-left: 1rem;
  padding-right: 1rem;
`


class ValidacionIdentidad extends Component {

  constructor(props) {
    super(props)
    this.state = {
      flujo: restoreFromSessionStorage('flujo'),
      signature: '',
      openBackDrop: false,
      errorService: false,

      btnSubmit: false,
      bdHabilita: false,
      actRadio: false,
      legales: '', 
      color_alert: 'warning',
      message: 'Firma Guardada exitosamente, ya puede cerrar esta ventana.',
      
      whatsapp: true,
      terminos:false,
      autorizar:false,   
      firmar_solicitud:false,   

      afiStateShort: restoreFromSessionStorageAfiShort('afiStateShort'),
      lang: this.props.pageContext.locale === "esES" ? 'es' : 'en',
      fronted: false,
      btn_firma:false,

    }
    
    this.handleChange = this.handleChange.bind(this)
  }

  async componentDidMount(){
    const {flujo}=this.state
    await catalogues.cavSectionGeneric(flujo.creditApplicationId,"source")
    .then((response)=>{    
      if(response.data.frontEnd ==="TYSON"){
        this.setState({fronted:true})
      }else{
        this.setState({
          terminos: true,
          aviso: true,                
          autorizar:true, 
        })
      }
    })
  }

  async submitStep() {
    this.setState({  bdHabilita: true, btnSubmit: false ,btn_firma:false})
    let flujo = restoreFromSessionStorage('flujo')
    const url = window && document.location.search
    const urlParams = new URLSearchParams(url);
    const id = urlParams.get('creditApplicationId');
    if (id) {
      flujo = { ...flujo, creditApplicationId: id }
      sessionStorage.setItem('flujo', JSON.stringify(flujo))
    }

    const prescore = new PrescoreService(flujo.creditApplicationId)
    let file = await DataUrlToFile(this.state.signature, `${flujo.creditApplicationId}.png`)    
    prescore.setChatOptIn(this.state.whatsapp)
    prescore.setSignature(file)
    try {
      const { data } = await prescore.send()
      
      if (data.approved) {    
        
        //CREATE SERVICE POLICES AFI
      catalogues.putPolicesAfi(flujo.creditApplicationId)
      .then((response) => {
          console.log("srvPolices=>",response.data?.canContinue)
          if(!response.data?.canContinue){
            this.setState({firmar_solicitud: false, errorService: true,  bdHabilita: false ,btn_firma:true})
          }else{
            this.setState({
              firmar_solicitud: false,
              color_alert: 'success',
              errorService: true,           
              bdHabilita: false,              
              btn_firma:true,
              btnSubmit: false          
             })            
            
          }
          
      })
      .catch(error => {
        this.setState({ errorService: true })
        console.error(error)
      })
       
      } else {
        // Enviar a pantalla lo-sentimos
        this.setState({firmar_solicitud: false, errorService: true,  bdHabilita: false })
      }
    } catch (error) {
      console.log("error", error);
      this.setState({ errorService: true,  bdHabilita: false })
    }

  }

  handleChange(prop, data) {

    const state = {
      [prop]: data.value
    }    

    const tmp = Object.assign({}, this.state, state)
    this.setState(tmp, () => {})

  }


  //VERIFICA SI SE CUMPLEN TODAS LA CONDICIONES PARA ENVIAR LA INFORMACION
  componentDidUpdate(prevProp, prevState) {
    const {
      signature,      
      terminos,
      autorizar,  
      btn_firma    
    } = this.state
    if (
      // signature !== ''  &&           
      terminos  &&
      autorizar  
    ) {
      if (!prevState.btnSubmit) {        
        this.setState({ btnSubmit: true })
      }
    } else if (prevState.btnSubmit) {      
      this.setState({ btnSubmit: false })
    }

    if (
      signature !== '' 
    ) {
      if (!prevState.btn_firma) {        
        this.setState({ btn_firma: true })
      }
    } else if (prevState.btn_firma) {      
      this.setState({ btn_firma: false })
    }

    

  }


  render() {
    const { classes } = this.props;

    const {
      titleIconCredito,
      boxMessage1,
      boxMessage2,
      buttonDegradeContinue,
      alertMessage,
      lblFirmarSolicitud
    } = this.props
    
    const {fronted}=this.state
    const modalSignature=    
                  <Grid container>
                    <Grid item xs={12}>
                    <Box>
                      <Signature onChange={e => { this.setState({ signature: e.value }) }} />
                    </Box>
                    </Grid>
                    <br/>&nbsp;
                    <Grid container justify="center">
                      <Grid item>
                        <div css={buttonCss} >
                        <Button onClick={() => this.submitStep()} color="primary" variant="contained" disabled={!this.state.btn_firma}>
                          {buttonDegradeContinue}
                        </Button>                          
                        </div>
                      </Grid>
                    </Grid>
                  </Grid>

    return (
      <Grid container justify="center">
        <Grid item xs={12} md={6} css={root}>
          <Grid container justify="center">
            <Box css={mx}>
              <TitleIcon title={titleIconCredito} icon={ThumbUpAltOutlinedIcon} textColor={true} />
            </Box>
          </Grid>
          <br />
            {fronted &&
              <Disclousers
              handleChange={this.handleChange}
              btnSubmit={this.state.btnSubmit}
              submitStepTwo={()=> this.submitStep()}
              whatsapp={this.state.whatsapp}
              direccion1={true}
              terminos={this.state.terminos}
              autorizar={this.state.autorizar}
              lang={this.state.lang}
              butonOn={false}
            />             
            }            
          <br/>
          <CardElevation >
            <Fragment>
              <Grid container>
                <Grid item xs={12}>
                  <Typography variant="body2" component="div">
                    <Box css={mb}>
                      {boxMessage1}
                    </Box>
                    <Box css={mb}>
                      {boxMessage2}
                    </Box>
                  </Typography>
                </Grid>
                &nbsp;<br />                
                  <Grid container justify="center">
                    <Grid item>
                      <div css={buttonCss} >
                        <ButtonDegrade
                          id="tus-datos-continuar-solicitud-button"
                          icon={ArrowForwardIcon}                          
                          textButton={lblFirmarSolicitud}
                          onClick={() => this.setState({ firmar_solicitud:true })} 
                          // disabled={this.state.btn_firma}
                          disabled={!this.state.btnSubmit}
                        />
                      </div>
                    </Grid>
                  </Grid>                                
                
              </Grid>
            </Fragment>
          </CardElevation>

        </Grid>


        <Backdrop open={this.state.bdHabilita} className={classes.backdrop}>
          <CircularProgress color="primary" />
        </Backdrop>
        <Snackbar open={this.state.errorService} autoHideDuration={8000} onClose={() => this.setState({ errorService: false })}>
          <Alert variant="filled" severity={this.state.color_alert} onClose={() => this.setState({ errorService: false })}>
            {this.state.color_alert ==="warning" ? alertMessage : this.state.message}
          </Alert>
        </Snackbar>
        <Dialog                
          open={this.state.firmar_solicitud}                
          id='dialogo-sign-your-application'                
          >
          <DialogContent >
              {modalSignature}
          </DialogContent>                    
        </Dialog>
      </Grid>
    );
  }
}

export default withStyles(useStyles)(withThemeProps(ValidacionIdentidad, 'VDPNValidacionIdentidadAfi'));


ValidacionIdentidad.propTypes = {
  titleIconCredito: PropTypes.string,
  boxMessage1: PropTypes.string,
  boxMessage2: PropTypes.string,
  buttonDegradeContinue: PropTypes.string,
  alertMessage: PropTypes.string,
  lblFirmarSolicitud:PropTypes.string
};

ValidacionIdentidad.defaultProps = {
  titleIconCredito: "Verificación de crédito",
  boxMessage1: "Con tu firma, nos autorizas obtener informes de tu historial de crédito, empleo y domicilio relacionados con tu solicitud de crédito, de conformidad con la ley aplicable y nuestra política de privacidad.",
  boxMessage2: <strong>A continuación te pedimos que firmes tal como aparece en tu identificación en el siguiente recuadro:</strong>,
  buttonDegradeContinue: "Continuar con solicitud",
  alertMessage: "Lo sentimos, por el momento el servicio no se encuentra disponible",
  lblFirmarSolicitud:"Firmar tu solicitud"
}